<template>
  <div>
    <el-tooltip
      v-if="isShow"
      :content="`${content}: ${scope.row[fields] || '⏤'}`"
      placement="bottom"
      effect="light"
    >
      <div class="triangle-topright" />
    </el-tooltip>

    <vxe-input
      ref="vxeInput"
      v-model.trim="row[column.property]"
      class="vxe-button-edit-render"
      v-bind="vxeInputPropsProxy"
      v-on="{
        ...otherEvent,
        change: onChange
      }"
      @focus="focus"
      @keyup="keyup"
    />
  </div>
</template>
<script>
import { get as getCookie } from '@/utils/cookies'
export default {
  name: 'VxeToolTipInput',
  props: {
    content: {
      type: String,
      default: ''
    },
    fields: {
      type: String,
      default: ''
    },
    vxeInputProps: {
      type: Object,
      default: () => ({})
    },
    otherEvent: {
      type: Object,
      default: () => ({})
    },
    autoselect: {
      type: Boolean,
      default: false
    },
    enterSave: {
      // 按下回车键后，自动失焦，保存数据
      type: Boolean,
      default: false
    },
    scope: {
      type: Object,
      default: () => ({
        row: {}, // 宿主表格行
        column: {} // 宿主表格列
      })
    },
    showTip: {
      type: [Boolean, Function],
      default: true
    }
  },
  data() {
    return {
      row: {}, // 宿主表格行
      column: {} // 宿主表格列
    }
  },

  computed: {
    getSize() {
      return this.$store.getters.size || getCookie('size') || 'mini'
    },
    vxeInputPropsProxy() {
      return {
        placeholder: '',
        size: this.getSize,
        ...this.vxeInputProps,
        disabled:
          typeof this.vxeInputProps?.disabled === 'function'
            ? this.vxeInputProps?.disabled(this.row)
            : this.vxeInputProps?.disabled
      }
    },
    isShow() {
      return typeof this.showTip === 'function' ? this.showTip() : this.showTip
    }
  },

  created() {
    Object.assign(this, this.scope)
  },
  mounted() {
    this.focus()
    this.keyup()
  },
  methods: {
    focus() {
      if (this.autoselect) {
        this.$refs.vxeInput.$refs.input.select()
      }
    },
    keyup(event = {}) {
      // eslint-disable-next-line no-unused-vars
      const { value, $event } = event || {}
      if (this.enterSave && $event?.key === 'Enter') {
        this.$refs.vxeInput.$refs.input.blur()
      }
    },
    onChange({ value, $event }) {
      if (this.otherEvent?.change) {
        this.otherEvent.change(this.scope, value, $event)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.triangle-topright {
  width: 0;
  height: 0;
  border-top: 8px solid #027aff;
  border-left: 8px solid transparent;
  position: absolute;
  top: 8px;
  right: 15px;
  z-index: 1;
  cursor: pointer;
}
</style>
