/**
 * @module api-scm:orderManage/orderInfo
 */
import service from '@/service'

/**
 * 订单列表   列表查询
 * @param {{
 * keywords:string,
 * limit:number,
 * page:number,
 * sort:string
 * }} params
 */
export function getList(data) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/get/getList',
    method: 'post',
    data
  })
}

export function getListDown(params) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/get/getOrderPullList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export function info(id) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/info',
    method: 'get',
    params: { id }
  })
}

/**
 * 待办详情 (无需权限)
 * @param {{string}} id
 * @returns
 */
export function todoInfo(id) {
  return service['api-scm']({
    url: '/public/activiti/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 创建
 * @param {object} data
 * @returns {object}
 */
export function create(data) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/save',
    method: 'post',
    data
  })
}
/**
 * 更新
 * @param {object} data
 * @returns {object}
 */
export function update(data) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/update',
    method: 'post',
    data
  })
}

/**
 * @param {Array<number|string>} ids
 * @description 提交
 */
export function submit(ids) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {number} id
 * @description 作废
 */
export function invalid(ids) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/cancellationOrder',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {number} id
 * @description 反审核
 */
export function rollback(id) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/reverseAudit',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {string} orderCodeList
 * @description 关闭
 */
export function close(orderCodeList) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/orderWholeClose',
    method: 'post',
    data: { orderCodeList },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {string} ids
 * @description 结束流程（结束审核流）
 */
export function withdraw(ids) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/withdraw',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * // 下推
 * @param {object} data
 */

export function pushDown(data) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/generatedOrder',
    method: 'post',
    data
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
// /**
//  * @param {Array<(number|string)>} ids
//  * @description 删除
//  */
// export function remove(ids) {
//   return service['api-scm']({
//     url: '/scm/order/orderInfo/delete',
//     method: 'delete',
//     params: {ids},
//     headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
//   })
// }

/**
 * @param {{params:Object}}
 * @description 导出 订单商品汇总统计
 */
export function exportOrderInfoData(data) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/orderInfo/export',
    method: 'post',
    data
    // responseType: 'blob', // 文件流接收类型声明转成 blob
    // saveAs: true // 文件流是否通过浏览器下载
  })
}

/**
 * @param {{params:Object}}
 * @description 导出 订单商品明细统计
 */
export function exportOrderInfoDetailData(data) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/orderInfoDetail/export',
    method: 'post',
    data
    // responseType: 'blob', // 文件流接收类型声明转成 blob
    // saveAs: true // 文件流是否通过浏览器下载
  })
}

/**
 * @param {{params:Object}}
 * @description 导出 发货商品详情
 */
export function exportSendDataInfoData(data) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/sendDataInfo/export',
    method: 'post',
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data
    // responseType: 'blob', // 文件流接收类型声明转成 blob
    // saveAs: true // 文件流是否通过浏览器下载
  })
}

/**
 * @param {{params:Object}}
 * @description 导出 订单拆单明细导出
 */
export function exportOrderSplitData(data) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/orderSplitData/export',
    method: 'post',
    data
    // responseType: 'blob', // 文件流接收类型声明转成 blob
    // saveAs: true // 文件流是否通过浏览器下载
  })
}

/**
 * @param {{
 * productCode:string,
 * salesCode:string,
 * sellerRemark:string,
 * orderDetailId:number
 * }} data
 */
export function updateSellerRemark(data) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/get/updateSellerRemark',
    method: 'post',
    data
  })
}

export function turnOffFin(idList) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/orderClose',
    method: 'post',
    data: { idList },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export function reverseCloseFin(idList) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/reverseOrderClose',
    method: 'post',
    data: { idList },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 手动对接
 * @param {string} ids
 */
export function manualDockingOfKingdee(ids) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/handButting',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 手动操作vc后台发货
 * @param {string} id
 */
export function manualDelivery({ id }) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/get/manualDelivery',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{detailId:number}} params
 */
export function getSellerRemarkOperationLogs(params) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/get/getSellerRemarkOperationLogs',
    method: 'get',
    params
  })
}

/**
 * 重新匹配 获取数据
 */
export function getMatchFailed(params) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/get/getMatchFailed',
    method: 'get',
    params
  })
}

/**
 * @description 重新匹配 确认匹配
 */
export function confirmMatch(data) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/get/confirmMatch',
    method: 'post',
    data
  })
}

/**
 * 模糊查询商品code的下拉
 * @param {{code?:string,supplierCode?:string}} params
 */
export function getAncillaryCode(params) {
  return service['api-web']({
    url: '/public/product/getAncillaryCode',
    method: 'get',
    params
  })
}

/**
 * @description 批量修改卖家备注
 */
export function batchUpdateSellerRemark(data) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/batchUpdateSellerRemark',
    method: 'post',
    data
  })
}
/**
 * @param {number} businessState
 * @param {Array} orderIdList
 * @description 订单整单关闭-反关闭
 */
export function orderClose(data) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/orderClose',
    method: 'post',
    data
  })
}
/**
 * @description 获取所有启用的订单标记
 */
export function getAllEnableOrderMark(params) {
  return service['api-web']({
    url: '/sys/orderMark/get/getAllEnableOrderMark',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @description 根据商品code进行精确查询
 */
export function getExactProductByCode(params) {
  return service['api-web']({
    url: '/public/product/getExactProductByCode',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 根据订单编码获取操作日志
 */
export function getOrderLog(orderCode) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/get/getOrderLog/' + orderCode,
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @typedef MatchFailedExportExcelItem
 * @property {null | number} id - id
 * @property {string} productCode - 系统商品编码
 * @property {string} productName - 系统商品名称
 * @property {string} platformProductName - 平台商品名称
 * @property {string} platformProductCode - 平台商品编码
 * @property {string} specificationsModel - 规格型号
 * @description 重新匹配,导出需要重新匹配的数据
 */
export function matchFailedExportExcel(data) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/matchFailedExportExcel',
    method: 'post',
    data,
    responseType: 'blob',
    saveAs: true
  })
}
/**
 * @param {file} businessState
 * @description 重新匹配,导入
 */
export function importMatchFailed(data) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/importMatchFailed',
    method: 'post',
    data
  })
}

/**
 *
 * @description 更新收货信息
 */
export function updateReceivingInfo(data = {}) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/batchUpdateAddressOrPhone',
    method: 'post',
    data
  })
}

/**
 * @param {{ids:string}} data
 * 删除
 */
export function remove(data) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/delete',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 批量反审核订单
 */
export function batchReverseAudit(data) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/batchReverseAudit',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {(number|string)} ids
 * @description 发货单打印
 */
export function shipInvoicePrint(data) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/shipInvoicePrint',
    method: 'post',
    data
    // headers: { 'Content-Type': 'application/json' }
  })
}

/**
 *
 * @param {string} orderCodes
 * @description 配送清单打印
 */
export function jdInvoicePrinting(orderCodes) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/invoicePrinting',
    method: 'get',
    params: { orderCodeList: orderCodes },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *
 * @param {string} orderCodes
 * @description 订单下载-京东
 */
export function orderDownloadedFromJD(data) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/orderDownloadedFromJD',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {object} data
 * @description 联络单列表
 */
export function interiorLiaisonQueryList(data) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/interiorLiaison/queryList',
    method: 'post',
    data
  })
}

/**
 * @param {{keywords:string}} params
 */
export function getUserlists(params) {
  return service['api-web']({
    url: '/public/getUserlists',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 店铺模糊查询下拉框（下拉公用）
 */
export function getStorePullOpts(params) {
  return service['api-web']({
    url: '/public/data/getStorePull',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{modeDespatch:number,deliveryWarehouse?:string}} params
 * @description 拆单明细 发货仓/调出仓库下拉,
 * 筛选表单查询订单时，使用 { modeDespatch:1 } 发货方式为采购时的仓库
 */
export function getAllocateWarehouse(params) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/get/getAllocateWarehouse',
    method: 'get',
    params
  })
}

export function infoList(id) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/get/infoAll',
    method: 'get',
    params: { id }
  })
}

/**
 * @param {object} data
 * @description  拆单内部联络单新增
 */
export function interiorLiaisonSave(data) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/interiorLiaison/save',
    method: 'post',
    data
  })
}

/**
 * @param {object} data
 * @description 联络单修改
 */
export function interiorLiaisonUpdate(data) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/interiorLiaison/update',
    method: 'post',
    data
  })
}

/**
 * @param {object} data
 * @description 批量作废
 */
export function batchCancellationOrderNew(data) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/batchCancellationOrderNew',
    method: 'post',
    data
  })
}
